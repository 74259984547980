import { fetchBaseQuery } from '@reduxjs/toolkit/query';

export const redisQuery = fetchBaseQuery({
  baseUrl: `${process.env.NEXT_PUBLIC_REDIS_API}/`,
  prepareHeaders: (headers) => {
    if (headers.get('Content-Type') === null) {
      headers.set('Content-Type', 'application/json');
    }

    headers.set('accept', '*/*');
    return headers;
  },
});
