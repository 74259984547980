import { createSlice } from '@reduxjs/toolkit';
import { SnackBarData } from '@shared/types/common';

interface CommonSliceProps {
  disableModalOutsideClick: boolean;
  snackbarData: SnackBarData | undefined;
  isLoading: boolean;
  isWarningOpen: boolean;
}

const initialState: CommonSliceProps = {
  disableModalOutsideClick: false,
  snackbarData: undefined,
  isLoading: false,
  isWarningOpen: true,
};

const commonSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setDisableModalOutsideClick(state, { payload }) {
      state.disableModalOutsideClick = payload || false;
    },
    setSnackbarData(state, { payload }) {
      state.snackbarData = payload || undefined;
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload || false;
    },
    setIsWarningOpen(state, { payload }) {
      state.isWarningOpen = payload;
    },
  },
});

export const {
  setDisableModalOutsideClick,
  setSnackbarData,
  setIsLoading,
  setIsWarningOpen,
} = commonSlice.actions;

export default commonSlice.reducer;
