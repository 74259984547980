import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CreateCampaignUserRequest,
  CreateCampaignUserResponse,
  LandingpageResponse,
} from '@shared/types/campaigns';
import fetchBaseQuery from '@utilities/fetchBaseQuery';

export const CampaignApi = createApi({
  reducerPath: 'CampaignApi',
  baseQuery: fetchBaseQuery,
  endpoints: (builder) => ({
    getLandingpage: builder.query<LandingpageResponse, string>({
      query: (landingpageId) => `landing-pages/${landingpageId}`,
    }),

    createUser: builder.mutation<
      CreateCampaignUserResponse,
      CreateCampaignUserRequest
    >({
      query: (body) => ({
        url: '/landing-pages/new-member',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetLandingpageQuery, useCreateUserMutation } = CampaignApi;
