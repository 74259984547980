import Button from '@components/Button';
import Link from '@components/Link';

const ExtendedButton = ({ appearance, link, children, id, ...props }) => {
  const handleButtonClick = (e) => {
    // Check if the href is an anchor link (starts with #)
    const isAnchorLink = link?.url?.startsWith('#');

    if (isAnchorLink) {
      // Prevent default navigation
      e.preventDefault();

      // Remove the # to get the ID
      const targetId = link.url.substring(1);
      const element = document.getElementById(targetId);

      if (element) {
        // Smooth scroll to the section
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
    // If it's not an anchor link, the default Link behavior will proceed
  };

  return (
    <Button
      element={Link}
      appearance={appearance}
      href={link?.url}
      onClick={handleButtonClick}
      {...(id && {
        'data-id': id,
      })}
      {...props}
    >
      {children || link?.title}
    </Button>
  );
};

export default ExtendedButton;
