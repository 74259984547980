import smoothscroll from 'smoothscroll-polyfill';
import { Provider as ReduxProvider } from 'react-redux';
import store from '@shared/store';
import i18nStringsContext from '@contexts/i18nStringsContext';
import { appWithTranslation } from 'next-i18next';
import { CookiesProvider } from 'react-cookie';
import Head from 'next/head';
import {
  ComponentType,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import 'intersection-observer';
import { useRouter } from 'next/router';
import SiteOptionsProvider from '@features/WordPress/components/SiteOptionsProvider';
import { GTM, isDevelopment } from '@utilities/environment';
import Script from 'next/script';
import '../shared/components/Adyen/AdyenDropin.css';
import '../shared/styles/app.css';
import DefaultLayout from '../shared/layouts/DefaultLayout';
import { AppProps as NextAppProps } from 'next/app';
import { DynamicDictionary, GeneralOptionsQuery } from '@shared/types';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import { v4 as uuidv4 } from 'uuid';
import { StatsigClient } from '@statsig/js-client';
import { StatsigProvider } from '@statsig/react-bindings';

type AppProps<P = any> = {
  pageProps: P;
} & Omit<NextAppProps<P>, 'pageProps'>;

export type Page<P = {}> = NextPage<P> & {
  // You can disable whichever you don't need
  getLayout?: (page: ReactElement) => ReactNode;
  layout?: ComponentType;
};

type Props = NextAppProps<GeneralOptionsQuery> & {
  Component: Page;
};

const App = ({ Component, pageProps }: Props) => {
  const { page, generalOptions }: GeneralOptionsQuery = pageProps || {};
  const { locale } = useRouter();
  const [isLoading, setIsLoading] = useState(true);
  const [statsigClient, setStatsigClient] = useState<StatsigClient | null>(
    null
  );

  if (typeof window !== 'undefined') {
    smoothscroll.polyfill();
  }

  let seo = {};

  if (page && page.seo) {
    seo = page.seo;
  }

  let options = null;

  if (generalOptions && generalOptions.options) {
    options = generalOptions.options;
  }

  const strings = options?.strings?.reduce<DynamicDictionary>((arr, cur) => {
    // no-param-reassign is a way to avoid us from inducing sideeffects outside of scope,
    // but the case of reduce is an exception to issue of sideeffects, since it is internally pure
    // eslint-disable-next-line no-param-reassign
    arr[cur.key] = cur.value;
    return arr;
  }, {});

  const hasBlackFadedHero =
    page?.sections?.sections[0]?.features?.includes('black_image_fade') ||
    false;

  useEffect(() => {
    const path = window.location.hash;

    if (path && path.includes('#')) {
      setTimeout(() => {
        const id = path.replace('#', '');
        const el = window.document.getElementById(id);

        if (!el) {
          return;
        }

        el.scrollIntoView({ behavior: 'smooth' });
      }, 1500);
    }
  }, []);

  const generalPageProps = {
    hasBlackFadedHero,
    template: page?.template?.id,
    ...options,
  };

  const getLayout =
    Component.getLayout ||
    ((content: ReactElement, props: any) => (
      <DefaultLayout {...props}>{content}</DefaultLayout>
    ));

  const NoSSRMasqueradeProvider = dynamic(
    () =>
      import(
        '@features/Auth/components/ProvideMasqueradeUser/ProvideMasqueradeUser'
      ),
    { ssr: false }
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let storedSessionId = sessionStorage.getItem('sessionId');

      if (!storedSessionId) {
        storedSessionId = uuidv4();
        sessionStorage.setItem('sessionId', storedSessionId);
      }

      const myStatsigClient = new StatsigClient(
        process.env.NEXT_PUBLIC_STATSIG_CLIENT_SDK_KEY as string,
        {
          userID: storedSessionId,
        },
        {
          environment: { tier: process.env.ENVIRONMENT },
        }
      );
      myStatsigClient
        .initializeAsync()
        .then(() => {
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
      setStatsigClient(myStatsigClient);
    }
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <ReduxProvider store={store}>
        <NoSSRMasqueradeProvider>
          <SiteOptionsProvider options={options}>
            <StatsigProvider client={statsigClient}>
              <Head>
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1"
                />
              </Head>
              <CookiesProvider>
                <i18nStringsContext.Provider value={{ ...strings }}>
                  {getLayout(
                    <Component
                      {...pageProps}
                      {...page}
                      seo={seo}
                      locale={locale}
                    />,
                    generalPageProps
                  )}
                  <Script
                    id="CookieConsent"
                    src="https://policy.app.cookieinformation.com/uc.js"
                    type="text/javascript"
                    strategy="afterInteractive"
                  />
                  <Script
                    id="google-tag-manager"
                    strategy="afterInteractive"
                    type="text/javascript"
                    dangerouslySetInnerHTML={{
                      __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${
                      GTM[locale!]
                    }');`,
                    }}
                  />
                  {(locale === 'da' || locale === 'dk') && (
                    <Script
                      id="dialogintelligens"
                      strategy="afterInteractive"
                      src="https://dialogintelligens.github.io/scripts/washworld.js"
                      type="text/javascript"
                      onLoad={() => console.log('Chatbot script loaded')}
                      onError={(e) =>
                        console.error('Chatbot script failed to load', e)
                      }
                    />
                  )}
                </i18nStringsContext.Provider>
              </CookiesProvider>
            </StatsigProvider>
          </SiteOptionsProvider>
        </NoSSRMasqueradeProvider>
      </ReduxProvider>
    </>
  );
};

export default appWithTranslation<AppProps>(App);
